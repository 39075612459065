import React from "react"
import "../styles/mindbody.scss"
import accountStyles from "../components/account.module.scss"
import Layout from '../components/layout-default'

const Privates = () => (
  <Layout
    title="Private Bachata &amp; Salsa Lessons in Orange County | RF Dance"
    description="Salsa &amp; Bachata Private Lessons in Orange County" 
    pathname="account"
    noHero="no-hero">
    <div className="container">
      <iframe 
        className={accountStyles.iframe}
        title="RF Dance Private Lesson Scheduler"
        id="mindbody_branded_web_cart_modal" 
        height="800"
        frameBorder="0" 
        marginWidth="0" 
        marginHeight="0" 
        scrolling="yes"
        src="https://app.acuityscheduling.com/schedule.php?owner=17766906" />
    </div>
  </Layout>
)

export default Privates